import { Cascader } from 'kenshin';
import { useEffect } from 'react';
import { connect } from 'umi';
// import areaData from '@vant/area-data';
import { areaList, useCascaderAreaData } from '@vant/area-data';
import { useReactive } from 'ahooks';

export const findAddressTree = (array, value) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].value === value) {
      return array[i];
    }
    if (array[i].children && array[i].children.length > 0) {
      const result = findAddressTree(array[i].children, value);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

const addressOptions = (arr) => {
  return arr.map((item) => {
    return {
      label: item.text,
      value: item.value,
      children: item.children ? addressOptions(item.children) : [],
    };
  });
};

const AddressSelect = (props) => {
  const { dispatch, user, ...resProps } = props;

  const state = useReactive({
    addressList: [],
  });

  useEffect(() => {
    let areaList = useCascaderAreaData();
    // console.log("areaList...",areaList);
    state.addressList = addressOptions(areaList);
    // console.log("areaList...2",addressOptions(areaList));
  }, []);

  return (
    <Cascader
      placeholder="请选择地址"
      options={user.addressList}
      // options={state.addressList}
      {...resProps}
    />
  );
};

export default connect(({ user }) => ({ user }))(AddressSelect);
