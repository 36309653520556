import { useEffect } from 'react';
import { titleArr, renderImportData, codeRegExp, addRemark } from './_tools';
import { useKeyPress } from 'ahooks';

/** 获取表格数据 */
const getDefaultData = (_importData, isShowRemark = false) => {
  if (isShowRemark) {
    addRemark(titleArr);
    console.log('titleArr===========', titleArr);
  }

  let celldata = luckysheet.transToCellData([
    titleArr,
    ...renderImportData(_importData),
  ]);
  let merge = {};
  console.log(renderImportData(_importData), 'luckysheet...celldata');
  celldata.forEach((item) => {
    if (item.v.mc && item.v.mc?.cs != undefined) {
      merge[`${item.r}_${item.c}`] = { ...item.v.mc };
    }
  });
  console.log(merge, 'luckysheet...merge');
  return {
    name: 'Sheet1',
    defaultRowHeight: 40, // 默认的行高
    color: '',
    frozen: { type: 'row' }, //  固定首列
    status: '1',
    order: '0',
    // data: [$exportData, ...new Array(10).fill([])],
    // data: $exportData,
    celldata,
    // data: [],
    config: { merge, columnlen: { 0: 150, 1: 800 } },
    index: 0,
    column: 5,
  };
};

const getOptions = (data) => {
  return {
    container: 'luckysheet', //luckysheet为容器id
    title: '绩效管理',
    lang: 'zh',
    data: [data],
    showinfobar: false,
    showsheetbar: false,
    hook: {
      cellUpdated(r, c, ovalue, nvalue) {
        if (r == 0 || c != 1 || !nvalue.v) {
          return;
        }
        // 获取当前输入文字是否含有编码
        let code = nvalue.v.toString().match(codeRegExp)?.[0];
        if (!code) {
          let lastStr = window.luckysheet.getCellValue(r - 1, c);
          if (!lastStr) return;
          let lastCode = lastStr.toString().match(codeRegExp)?.[0] ?? '1.0';
          if (lastCode) {
            let codeArr = lastCode.split('.');
            codeArr[codeArr.length - 1] = +codeArr[codeArr.length - 1] + 1;
            let newCode = codeArr.join('.');
            window.luckysheet.setCellValue(r, c, {
              v: `${newCode} ${nvalue.v}`,
            });
          }
        }
      },
    },
  };
};

const Sheet = ({ dataSource, isShowRemark }) => {
  // 快捷键 ctrl+M ：合并单元格
  useKeyPress(['ctrl.m'], () => luckysheet.setRangeMerge('all'));
  useEffect(() => {
    let options = getOptions(getDefaultData(dataSource, isShowRemark));
    console.log('luckysheet...', options);
    luckysheet.create(options);
  }, []);
  return (
    <div
      id="luckysheet"
      style={{
        margin: ' 0px',
        padding: ' 0px',
        width: '100%',
        height: '100%',
      }}
    ></div>
  );
};

export default Sheet;
