import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Button, Col, message, MessageBox, Row, Empty } from 'kenshin';
import { connect, history } from 'umi';
import TreeData from '../../components/TreeData';
import Quarter from './components/quarter';
import DataForm from './components/dataForm';
import './index.less';

// 根据osaWeekOfYear获取周的开始与结束时间
const getdateOfWeek = (osaWeekOfYear) => {
  if (!osaWeekOfYear) {
    return {};
  }
  let year = osaWeekOfYear.slice(0, 4);
  let week = +osaWeekOfYear.slice(4) - 1;
  let startYear = moment().set('year', year).startOf('years');
  let curDate = startYear.add(week, 'week');
  let osaBeginTime = curDate
    .startOf('week')
    .startOf('date')
    .add(1, 'day')
    .format('YYYY-MM-DD HH:mm:ss');
  let osaEndTime = curDate
    .endOf('week')
    .endOf('date')
    .add(1, 'day')
    .format('YYYY-MM-DD HH:mm:ss');
  return { osaBeginTime, osaEndTime };
};

console.log(123456);

let { osaBeginTime: defaultOsaBeginTime, osaEndTime } = getdateOfWeek(
  history.location.query.osaWeekOfYear,
);

const Template = ({ dispatch, performance }) => {
  const [treeKay, setTreeKay] = useState('');
  const [muid, setMuid] = useState(history.location.query.muId);
  const [empName, setEmpName] = useState('');
  const [quarterTime, setQuarterTime] = useState({});
  const [osaBeginTime, setOsaBeginTime] = useState(defaultOsaBeginTime);
  const [curYear, setCurYear] = useState(moment().year());

  const treeHandleChange = (keys, rows, { expandedKeys }) => {
    if (!rows) return;
    if (rows?.muid) {
      setMuid(rows.muid);
      setEmpName(rows.title);
    }
    setTreeKay(keys[0]);
    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: { rows, expandedKeys },
      },
    });
  };

  const quarterChange = (time) => {
    setQuarterTime({ ...time });
  };

  const onYearChange = (year) => {
    console.log('onYearChange...', year);
    if (year) {
      setCurYear(year);
    }
  };

  useEffect(() => {
    if (history.location.query.busiId) {
      dispatch({
        type: 'performance/getosaemployeedata',
        payload: {
          busiId: history.location.query.busiId,
        },
      }).then((res) => {
        if (res) {
          console.log(111111, res);
          setOsaBeginTime(res?.osaBeginTime);
          setMuid(res.osaMuId);
          dispatch({
            type: 'performance/save',
            payload: {
              curOsaSettingData: {
                ...performance.curOsaSettingData,
                rows: { ...res, title: res.userInfo.value, key: res.osaMuId },
              },
            },
          });
        }
      });
    }
    return () => {
      dispatch({
        type: 'performance/save',
        payload: {
          curOsaSettingData: {},
        },
      });
    };
  }, []);

  return (
    <>
      <Row gutter="20" style={{ height: '100%' }}>
        {!history.location.query.muId && !history.location.query.busiId && (
          <Col span={4}>
            <TreeData onChange={treeHandleChange} />
          </Col>
        )}
        <Col span={history.location.query.muId ? 5 : 3}>
          <Quarter
            data={performance.weekTimeTree}
            curData={performance.curOsaSettingData?.rows ?? {}}
            value={osaBeginTime}
            onChange={quarterChange}
            onYearChange={onYearChange}
            disabled={history.location.query.target == 'E'}
            dispatch={dispatch}
          />
        </Col>
        <Col span={history.location.query.muId ? 15 : 12}>
          {muid ? (
            <DataForm
              curYear={curYear}
              deptId={treeKay}
              {...quarterTime}
              muId={muid}
              empName={empName}
            />
          ) : (
            <Empty description="请选择需要创建周计划的成员" />
          )}
        </Col>
      </Row>
    </>
  );
};

export default connect(({ performance, system, loading, user }) => {
  return { performance };
})(Template);
