import { sysLogin, getuserfunctions } from '@/_serveice/user';
import { history } from 'umi';
import request from 'umi-request';
import { MessageBox } from 'kenshin';
import { areaList, useCascaderAreaData } from '@vant/area-data';

// 谷歌地址API
async function getAddressListApi() {
  return request(
    'https://restapi.amap.com/v3/config/district?key=6ad48069574e18946af4cc8d02aeec46&subdistrict=3',
    {
      noHeaders: true,
    },
  );
}

async function testRequest() {
  return request(
    'http://192.168.1.100:8008/v1/waper/erp/pc/login/employeelogin',
    {
      method: 'POST',
      noHeaders: true,
      data: {
        browserType: '',
        captcha: '2255',
        empLoginPwd: 'qlOSIvXXQgF4gq/IseZP6w==',
        empNumber: 'fGyPNVCeQKxZ0Yoat91IIA==',
        requestIp: '',
        systemType: '',
        uniqueKey: '123',
      },
    },
  );
}

// const addressOptions = (arr) => {
//   return arr.map((item) => {
//     return {
//       label: item.name,
//       value: item.adcode,
//       children: addressOptions(item.districts),
//     };
//   });
// };

const addressOptions = (arr) => {
  return arr.map((item) => {
    return {
      label: item.text,
      value: item.value,
      children: item.children ? addressOptions(item.children) : [],
    };
  });
};

export default {
  namespace: 'user',

  state: {
    currentRouter: '',
    menuList: [],
    hasLocalServer: false,
    addressList: [],

    curMenuId: null,
    buttonPermissionList: [],
    tableHeadPermissionList: [],
  },

  // 类似vue的actions，里面可用执行异步操作
  effects: {
    *login({ payload, remember, hasDefaultPwd }, { put, call, select }) {
      const res = yield call(sysLogin, payload);
      if (res?.success) {
        // 只在正式环境开放
        // if (hasDefaultPwd && process.env.model == 'production') {
        if (hasDefaultPwd) {
          MessageBox.confirm({
            title: '系统提示',
            content: '检测到是默认密码，请尽快修改密码',
          });
        }
        // 是否记住账号和密码
        if (remember) {
          let userAorP = JSON.stringify({
            empNumber: payload.empNumber,
            empLoginPwd: payload.empLoginPwd,
          });
          localStorage.setItem('userAorP', userAorP);
        } else {
          localStorage.removeItem('userAorP');
        }
        // 成功后存储系统数据
        localStorage.setItem('token', res.data.accessToken);
        localStorage.setItem('muId', res.data.muId);
        localStorage.setItem('refreshToken', res.data.refreshToken);
        localStorage.setItem('manageDeptId', res.data.manageDeptId); // 管理的部门的id，用于考核模块
        // 菜单
        res.data?.sysMenuTree?.child &&
          localStorage.setItem(
            'menu',
            JSON.stringify(res.data?.sysMenuTree?.child),
          );
        // 表头权限
        res.data?.tableHeadPermissionList &&
          localStorage.setItem(
            'tableHeadPermissionList',
            JSON.stringify(res.data?.tableHeadPermissionList),
          );
        // 表格按钮权限
        res.data?.buttonPermissionList &&
          localStorage.setItem(
            'buttonPermissionList',
            JSON.stringify(res.data?.buttonPermissionList),
          );
        // 用户信息
        if (res.data?.employeeDTO) {
          let empData = {
            ...res.data?.employeeDTO,
            roleList: res.data.roleList,
            roleTypeList: res.data.roleList?.map((item) => item.extendData),
          };
          localStorage.setItem('employeeDTO', JSON.stringify(empData));
        }

        yield put({
          type: 'save',
          payload: {
            menuList: res.data?.sysMenuTree?.child,
          },
        });
        history.push('/');
        return res.data;
      }
    },
    *getAddressList({ payload }, { put, call, select }) {
      // const res = yield call(getAddressListApi, payload);
      // // const res1 = yield call(testRequest);
      // if (res.info == 'OK') {
      //   let addressList = addressOptions(res.districts[0].districts);
      //   yield put({
      //     type: 'save',
      //     payload: {
      //       addressList,
      //     },
      //   });
      //   return addressList;
      // }

      let areaList = useCascaderAreaData();
      if (areaList) {
        let addressList = addressOptions(areaList);
        yield put({
          type: 'save',
          payload: {
            addressList,
          },
        });
        return addressList;
      }

      return false;
    },
    *getuserfunctions({ payload }, { put, call, select }) {
      const res = yield call(getuserfunctions, payload);
      if (res?.success) {
        yield put({
          type: 'save',
          payload: {
            // menuList: menuTransform(res.sysFunctionTree.child)
            menuList: res.data.child,
          },
        });
      }
    },
    *setProps({ payload }, { put, select }) {
      let componentList = yield select((state) => state.home.componentList);
      let currentElement = yield select((state) => state.home.currentElement);
      if (payload.propsName) {
        currentElement[payload.propsName] = payload.value;
      } else if (payload.key === 'children') {
        currentElement.children = payload.value;
      } else {
        currentElement.props[payload.key] = payload.value;
      }
      // const i = componentList.findIndex(item => item.key == currentElement.key);

      let findRes = findComponent(componentList, currentElement);
      findRes.parent.splice(
        findRes.indexs[findRes.indexs.length - 1],
        1,
        currentElement,
      );
      yield put({
        type: 'save',
        payload: {
          currentElement,
          componentList,
        },
      });
    },
  },

  reducers: {
    // 每次保存数据的时候讲state再存一次，避免下一次数据存储的时候把原数据删了
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
