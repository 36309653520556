import { Alert, Input, InputNumber } from 'kenshin';
import Formula from './components/formula';

export const dirCloumns = ({
  dirTree,
  maxWeight,
  setMaxWeight,
  onClear,
  showRemark = false,
}) => {
  return [
    {
      label: '上级目录',
      name: 'osaIndexCatgPantId',
      type: 'TreeSelect',
      props: {
        placeholder: '请选择上级目录',
        treeDefaultExpandAll: true,
        fieldNames: {
          label: 'osaIndexCatgName',
          value: 'id',
          children: 'childCatgList',
        },
        allowClear: true,
        // options: [],
        treeData: dirTree,
        onSelect(val, row) {
          setMaxWeight(row.osaIndexRemainWeight);
        },
        onChange(val) {
          if (val == undefined) {
            onClear?.();
          }
        },
      },
    },
    {
      label: '目录名称',
      name: 'osaIndexCatgName',
      required: '请输入目录名称',
      type: 'TextArea',
      props: {
        placeholder: '请输入目录名称',
        autoSize: { minRows: 2, maxRows: 6 },
      },
    },
    // {
    //   label: '描述',
    //   name: 'remark',
    //   type: 'TextArea',
    //   props: {
    //     autoSize: { minRows: 2, maxRows: 6 },
    //     placeholder: '请输入描述',
    //   },
    // },
    {
      label: '指标权重',
      name: 'weight',
      type: 'InputNumber',
      required: showRemark ? undefined : '请输入指标权重',
      extra: `绩效指标剩余权重：${maxWeight ? maxWeight : ''}%`,
      props: {
        placeholder: '请输入指标权重，单位是%',
        max: 100,
        stepType: 'inside',
        min: 0,
        formatter: (value) => `${value}%`,
      },
    },
  ];
};

export const monthCloumns = ({
  dirTree,
  maxWeight,
  setMaxWeight,
  onClear,
  showRemark = false,
  contents,
  curIndex,
  onAdd,
  onDelete,
  onChange,
}) => {
  return [
    {
      label: '月份',
      name: 'osaIndexCatgName',
      required: '请输入月份',
      type: 'Input',
      props: {
        placeholder: '请输入月份',
        onChange: (e) => {
          console.log('contents...', e.target.value);
        },
      },
    },
    {
      label: '月度策略',
      name: 'childList',
      required: '请输入月度策略',
      type: 'TextArea',
      props: {
        placeholder: '请输入月度策略',
        autoSize: { minRows: 2, maxRows: 10 },
        onChange: (e) => {
          console.log('contents...', e.target.value);
        },
        //           onChange={(e) => {
        //             item.osaIndexItemName = e.target.value;
        //             console.log('contents...', item.osaIndexItemName);
        //             onChange(contents);
        //           }}
      },
    },
  ];
};

export const itemCloumns = ({
  dirTree,
  editShowData,
  setEditShowData,
  maxWeight,
  setMaxWeight,
  onClear,
  showRemark = false,
}) => {
  const getTargetData = () => {
    if (editShowData.osaIndexItemModel == 1) {
      return [
        // {
        //   render: () => <Alert title="实际值" type="info" closable={false} />,
        //   formItemProps: {
        //     wrapperCol: 20,
        //   },
        // },
        // {
        //   label: '数据来源',
        //   name: 'osaIndexItemActualValueSource',
        //   type: 'RadioGroup',
        //   required: '请选择数据来源',
        //   props: {
        //     options: [{ label: '手动输入', value: 1 }],
        //   },
        // },
        // {
        //   label: '指标单位',
        //   name: 'osaIndexItemActualValueUnit',
        //   type: 'Input',
        //   required: '请输入指标单位',
        //   props: {
        //     placeholder: '请输入指标单位',
        //   },
        // },
        {
          render: () => <Alert title="目标值" type="info" closable={false} />,
          formItemProps: {
            wrapperCol: 20,
          },
        },
        {
          label: '数据来源',
          name: 'osaIndexItemGoalValueSource',
          type: 'RadioGroup',
          required: '请选择数据来源',
          props: {
            options: [{ label: '手动输入', value: 1 }],
          },
        },
        {
          label: '目标值',
          name: 'osaIndexItemGoalValue',
          type: 'Input',
          required: '请输入目标值',
          props: {
            placeholder: '请输入目标值',
          },
        },
        {
          label: '指标单位',
          name: 'osaIndexItemGoalValueUnit',
          type: 'Input',
          required: '请输入指标单位',
          props: {
            placeholder: '请输入指标单位',
          },
        },
      ];
    }
    return [];
  };

  // console.log("showRemark1730,",showRemark);

  return [
    {
      render: () => <Alert title="基础信息" type="info" closable={false} />,
      formItemProps: {
        wrapperCol: 20,
      },
    },
    {
      label: '上级目录',
      name: 'osaIndexCatgId',
      type: 'TreeSelect',
      props: {
        placeholder: '请选择上级目录',
        treeDefaultExpandAll: true,
        fieldNames: {
          label: 'osaIndexCatgName',
          value: 'id',
          children: 'childCatgList',
        },
        allowClear: true,
        // options: [],
        treeData: dirTree,
        onSelect(val, row) {
          setMaxWeight(row.osaIndexRemainWeight);
        },
        onChange(val) {
          if (val == undefined) {
            onClear?.();
          }
        },
      },
    },
    {
      label: '指标名称',
      name: 'osaIndexItemName',
      required: '请输入指标名称',
      type: 'TextArea',
      props: {
        placeholder: '请输入指标名称',
        autoSize: { minRows: 2, maxRows: 6 },
      },
    },
    // {
    //   label: '描述',
    //   name: 'remark',
    //   type: 'TextArea',
    //   props: {
    //     placeholder: '请输入描述',
    //     autoSize: { minRows: 2, maxRows: 6 },
    //   },
    // },
    {
      label: '指标权重',
      name: 'weight',
      extra: `绩效指标剩余权重：${maxWeight ? maxWeight : ''}%`,
      type: 'InputNumber',
      required: showRemark ? undefined : '请输入指标权重',
      props: {
        placeholder: '请输入指标权重，单位是%',
        max: 100,
        stepType: 'inside',
        min: 0,
        formatter: (value) => `${value}%`,
      },
    },
    {
      label: '指标模式',
      name: 'osaIndexItemModel',
      type: 'RadioGroup',
      required: '请选择指标模式',
      props: {
        options: [
          { label: '定量', value: 1 },
          { label: '定性', value: 2 },
        ],
        onChange: (e) => {
          let value = e.target.value;
          setEditShowData((data) => {
            data.osaIndexItemModel = value;
            return { ...data };
          });
        },
      },
    },

    showRemark
      ? {
          label: '备注',
          name: 'remark',
          // required: '请输入备注',
          type: 'TextArea',
          props: {
            placeholder: '请输入备注',
            autoSize: { minRows: 2, maxRows: 6 },
          },
        }
      : {},

    ...getTargetData(),
    // {
    //   label: '目标值',
    //   name: 'osaIndexItemGoalValue',
    //   type: 'Input',
    //   required: '请输入目标值',
    //   props: {
    //     placeholder: '请输入目标值',
    //   },
    // },
    // {
    //   label: '指标单位',
    //   name: 'osaIndexItemGoalValueUnit',
    //   type: 'Input',
    //   required: '请输入指标单位',
    //   props: {
    //     placeholder: '请输入指标单位',
    //   },
    // },
    {
      render: () => <Alert title="得分计算" type="info" closable={false} />,
      formItemProps: {
        wrapperCol: 20,
      },
    },
    {
      label: '评分方式',
      name: 'osaIndexItemScoreMethod',
      type: 'RadioGroup',
      required: '请选择评分方式',
      props: {
        options: [
          { label: '手动输入', value: 1 },
          // { label: '公式计算', value: 2 },
        ],
        // onChange: (e) => {
        //   let value = e.target.value;
        //   setEditShowData((data) => {
        //     data.osaIndexItemScoreMethod = value;
        //     return { ...data };
        //   });
        // },
      },
    },
    // editShowData.osaIndexItemScoreMethod == 1
    //   ? {
    //       label: '评分',
    //       name: 'osaIndexItemScore',
    //       type: 'Input',
    //       required: '请输入评分',
    //       props: {
    //         placeholder: '请输入评分',
    //       },
    //     }
    //   : {
    //       label: '表达式得分',
    //       name: 'osaIndexItemScoreExpression',
    //       required: '请输入公式',
    //       render: () => <Formula />,
    //     },
  ];
};
