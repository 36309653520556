import {
  Col,
  Input,
  Row,
  Button,
  DatePicker,
  Alert,
  MessageBox,
} from 'kenshin';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useReactive } from 'ahooks';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import Select from '../../../../_util/components/Select';
import { timeDiff } from './../../../../_util/componentsUtil';
import {
  divideTimeIntoHalfDays,
  getattendanceupdowndays,
  getattendancehourtime,
} from '../_util';

// 增加、删除按钮样式
const buttonStyle = {
  borderRadius: '16px',
  width: '36px',
  height: '32px',
  textAlign: 'center',
  padding: 0,
  marginLeft: 10,
  position: 'absolute',
  right: '10px',
  top: 10,
};

const CastOptions = ({
  value,
  onChange,
  list = [],
  disabled,
  isEndDisabled = false,
  disabledWithoutTime = false,
  disabledTime = [],
}) => {
  console.log('HolidayOptions。value1', list);

  const [valueList, setValueList] = useState([
    {
      applyTime: null,
      applyType: null,
      applyTimeHour: 0,
      applyUpDays: 0,
      applyDownDays: 0,
    },
  ]);

  let alertRef = useRef({});

  useEffect(() => {
    alertRef.current = list.reduce((obj, item) => {
      obj[item.value] = item.remark || item.vacationRemark;
      return obj;
    }, {});
    if (value) {
      // console.log("valueList1...",value);
      setValueList(value);
    }
  }, [value]);

  const handleChange = () => {
    // console.log('valueList2...', value);
    setValueList([...valueList]);
    onChange?.([...valueList]);
  };

  const tipsMessageBox = (item, vacationRemainEnableUpDays) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '您当前的上半天不足，是否使用1个下半天抵扣上半天。',
      onOk: () => {
        // console.log('valueList2...', value, valueList);
        item.isDownReplaceUp = 1;

        item.downReplaceUpNum = item.applyUpDays - vacationRemainEnableUpDays;
        // item.applyTimeHour += item.downReplaceUpNum * 1.5;
        //替换后还是传上半天的时间
        item.applyTimeHour *= item.downReplaceUpNum;

        console.log('valueList2...item', item.applyTimeHour);
        // console.log('valueList2...valueList', value, valueList);

        setValueList([...valueList]);
        onChange?.([...valueList]);
      },
    });
  };

  const handleDisabled = (current, disabledTime) => {
    if (disabledTime) {
      return (
        current < moment(disabledTime[0]) || current > moment(disabledTime[1])
      );
    } else {
      return (
        current > moment().add(8, 'month') ||
        current < moment().subtract(3, 'month')
      );
    }
  };

  const add = () => {
    valueList.push({ applyTime: null, applyType: null, applyTimeHour: 0 });
    handleChange();
  };
  const deleteItem = (i) => {
    valueList.splice(i, 1);
    handleChange();
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  //
  const handleDisabledTime = (current, type, disabledTime) => {
    let [startTime, endTime] = disabledTime;
    if (type === 'start') {
      return {
        disabledHours: () => {
          if (startTime.isSame(moment(current), 'day')) {
            // 如果是起始日期当天，禁用起始时间之前的所有小时
            return Array(startTime.hour())
              .fill()
              .map((_, i) => i);
          } else if (endTime.isSame(moment(current), 'day'))
            return range(endTime.hour(), 24);
          return [];
        },
        disabledMinutes: () => {
          if (
            startTime.isSame(moment(current), 'day') &&
            startTime.hour() === moment(current).hour()
          ) {
            // 如果是起始日期当天且是当前小时，禁用起始时间之前的所有分钟
            return Array(startTime.minute())
              .fill()
              .map((_, i) => i);
          }
          return [];
        },
        disabledSeconds: () => {
          if (
            startTime.isSame(moment(current), 'day') &&
            startTime.hour() === moment(current).hour() &&
            startTime.minute() === moment(current).minute()
          ) {
            // 如果是起始日期当天且是当前小时且是当前分钟，禁用起始时间之前的所有秒数
            return Array(startTime.second())
              .fill()
              .map((_, i) => i);
          }
          return [];
        },
      };
    }

    if (type === 'end') {
      return {
        disabledHours: () => {
          if (endTime.isSame(moment(current), 'day')) {
            // 如果是结束日期当天，禁用结束时间之后的所有小时
            return Array(24 - endTime.hour() - 1)
              .fill()
              .map((_, i) => endTime.hour() + i + 1);
          } else if (startTime.isSame(moment(current), 'day'))
            return Array(startTime.hour())
              .fill()
              .map((_, i) => i);
          return [];
        },
        disabledMinutes: () => {
          if (
            endTime.isSame(moment(current), 'day') &&
            endTime.hour() === moment(current).hour()
          ) {
            // 如果是结束日期当天且是当前小时，禁用结束时间之后的所有分钟
            return Array(60 - endTime.minute())
              .fill()
              .map((_, i) => endTime.minute() + i + 1);
          }
          return [];
        },
        disabledSeconds: () => {
          if (
            endTime.isSame(moment(current), 'day') &&
            endTime.hour() === moment(current).hour() &&
            endTime.minute() === moment(current).minute()
          ) {
            // 如果是结束日期当天且是当前小时且是当前分钟，禁用结束时间之后的所有秒数
            return Array(60 - endTime.second())
              .fill()
              .map((_, i) => endTime.second() + i + 1);
          }
          return [];
        },
      };
    }
  };
  // console.log('HolidayOptions。value', valueList);

  return (
    <div>
      {valueList.map((item, i) => (
        <Row
          gutter={20}
          key={i}
          style={{
            padding: '10px',
            position: 'relative',
          }}
        >
          <Col span={8}>
            <Select
              value={item.applyType}
              placeholder="申请类型"
              disabled={disabled || isEndDisabled || disabledWithoutTime}
              onChange={(value) => {
                item.applyType = value;
                // console.log("申请类型...",item);
                handleChange();
              }}
              options={list}
            />
          </Col>
          <Col span={8}>
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              value={item.applyTime}
              disabled={disabled}
              disabledDate={(current) => {
                if (isEndDisabled) {
                  return handleDisabled(current, disabledTime?.[i]);
                }
              }}
              disabledTime={(current, type) => {
                if (isEndDisabled) {
                  return handleDisabledTime(current, type, disabledTime[i]);
                }
              }}
              showTime={{
                defaultValue: [
                  moment().set({ h: 9, m: 0, s: 0 }),
                  moment().set({ h: 18, m: 0, s: 0 }),
                ],
              }}
              onChange={async (date) => {
                item.applyTime = date;

                if (Array.isArray(date) && date[0] && date[1]) {
                  let applyBeginTime = moment(date[0]).format(
                    'YYYY-MM-DD HH:mm:ss',
                  );
                  let applyEndTime = moment(date[1]).format(
                    'YYYY-MM-DD HH:mm:ss',
                  );

                  if (item.applyType?.split('-')[0] === '4') {
                    //调休假获取上下半天计算申请时长
                    let res = await getattendanceupdowndays({
                      ...item,
                      applyBusiType: 15,
                      applyBeginTime,
                      applyEndTime,
                    });
                    if (res && res.data) {
                      item.applyUpDays = res.data.upDays;
                      item.applyDownDays = res.data.downDays;

                      item.applyTimeHour =
                        item.applyUpDays * 3 + item.applyDownDays * 4.5;
                    }
                  } else {
                    item.applyUpDays = undefined;
                    item.applyDownDays = undefined;

                    let res = await getattendancehourtime({
                      ...item,
                      applyBusiType: 15,
                      applyBeginTime,
                      applyEndTime,
                    });
                    if (res) {
                      item.applyTimeHour = res.data ? res.data.timeHour : 0;
                    }
                    console.log('getattendancehourtime...', item.applyTimeHour);
                  }

                  let foundItem = list.find(
                    (child) => child.value === item.applyType,
                  );

                  console.log('调休参数检测...', item.applyUpDays, foundItem);
                  //申请的上半天数大于剩余的上半天数量,下半天足够替换申请的天数

                  foundItem.vacationRealEnableUpDays =
                    foundItem.vacationRemainEnableUpDays +
                    foundItem.vacationRemainEnableLockUpDays;
                  foundItem.vacationRealEnableDownDays =
                    foundItem.vacationRemainEnableDownDays +
                    foundItem.vacationRemainEnableLockDownDays;

                  if (
                    item.applyUpDays > foundItem.vacationRealEnableUpDays && //用户申请上半天数大于可用上半天数
                    foundItem.vacationRealEnableDownDays >=
                      item.applyUpDays - foundItem.vacationRealEnableUpDays && //可用下半天数 大于 (申请上半天 - 大于可用的上半天数)
                    item.applyUpDays > 0 && //用户申请天数大于0
                    foundItem.vacationRealEnableUpDays * 3 +
                      foundItem.vacationRealEnableDownDays * 4.5 >=
                      item.applyUpDays * 3 + item.applyDownDays * 4.5 //可用时间要比申请时间多才弹窗
                  ) {
                    tipsMessageBox(item, foundItem.vacationRealEnableUpDays);
                  } else {
                    item.isDownReplaceUp = 0;
                  }

                  // console.log(
                  //   '选择了时间。。。。。',
                  //   foundItem.vacationRemainEnableDays,
                  //   item.applyTimeHour / 7.5,
                  // );
                }

                handleChange();
              }}
            />
          </Col>
          {!isEndDisabled && i == 0 ? (
            <Button
              type="primary"
              style={buttonStyle}
              onClick={add}
              disabled={disabled}
            >
              <PlusOutlined />
            </Button>
          ) : (
            !isEndDisabled && (
              <Button
                type="danger"
                disabled={disabled}
                style={buttonStyle}
                onClick={() => deleteItem(i)}
              >
                <MinusOutlined />
              </Button>
            )
          )}
          {isEndDisabled && valueList?.length > 1 && (
            <Button
              type="danger"
              disabled={disabled}
              style={buttonStyle}
              onClick={() => deleteItem(i)}
            >
              <MinusOutlined />
            </Button>
          )}
          {item.applyType && (
            <Col span={20}>
              <Alert
                type="info"
                key={item.applyType}
                style={{ marginTop: 10 }}
                description={alertRef.current[item.applyType]}
                showIcon
              />
            </Col>
          )}
          <Col span={20}>
            <div
              style={{
                marginTop: '10px',
                position: 'relative',
                background: 'rgba(67, 137, 249, 0.1)',
                borderRadius: '2px',
                padding: '5px 8px',
                color: '#4389F9',
                display: 'inline-block',
              }}
            >
              {item.applyType?.split('-')[0] !== '4' && (
                <>申请时长：{item.applyTimeHour}h</>
              )}

              {item.applyType?.split('-')[0] === '4' && (
                <>
                  {item.applyUpDays}个上半天、
                  {item.applyDownDays}个下半天
                </>
              )}
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default CastOptions;
