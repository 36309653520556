import { Typography, Button, message, Timeline } from 'kenshin';
import CollapseCard, { Template } from '../../../components/CollapseCard';
import { history, connect, useHistory } from 'umi';
import { OSATYPEKEY } from '../../_keys';
import { useEffect, useRef, useState } from 'react';
import ModalFormPro from '@/components/ModalFormPro';
import AutoSend from '../../../components/AutoSend';
import { floatIndicators } from '../../OSA/OSAsetting/_unitl';
import { CloseCircleFilled } from '@ant-design/icons';

// 状态管理的actions
const dispatchApi = {
  GETTREE: 'performance/getosauserindextree',
  GETDIRTREE: 'performance/getosauserindexcatgtree',
  UPDATEDIR: 'performance/updateosaemployeeindexcatgdata',
  UPDATEITEM: 'performance/updateosaemployeeindexitemdata',
  DELETEDIR: 'performance/deleteosaemployeeindexcatgdata',
  DELETEITEM: 'performance/deleteosaemployeeindexitemdata',
  ADDTREE: 'performance/addosaemployeeindexdata',
  MOVEDIR: 'performance/moveosaemployeeindexcatgdata',
  MOVEITEM: 'performance/moveosaemployeeindexitemdata',
  IMPOERSHEET: 'performance/importosaempindexdata',
  COPYHISTORY: 'performance/copyhistoryosaindexdata',
};
const getWeekOfMorY = (time) => {
  let weeks = moment(time).weeks();
  let osaYear = moment(time).year();
  let osaWeekOfYear = `${weeks}`;
  return {
    osaWeekOfYear,
    osaYear,
  };
};

const AUDITTYPE = {
  SUCCESS: 1,
  ERROR: 2,
};

const targetTYPE = {
  edit: 'E',
};

const curMuId = JSON.parse(localStorage.getItem('employeeDTO'))?.empId;

const isShowConfirmBtn = (row, processList) => {
  // console.log(1112333,processList,row)
  if (row?.osaId && processList.length == 0) return true;
  let curProcess = processList?.filter((item) => item.curnNode) || [];
  let nodeHandle = curProcess[curProcess.length - 1]?.nodeHandlerList || [];
  if (!nodeHandle.includes(curMuId)) {
    return false;
  }
  if (row) {
    let auditList = ['AUDIT_WEEK_PLAN_TWO_NODE', 'AUDIT_WEEK_PLAN_THREE_NODE'];
    let createStage = [
      null,
      'WEEK_PLAN_BEGIN_NODE',
      'CONFIRM_WEEK_PLAN_NODE',
      'CREATE_WEEK_PLAN_NODE',
    ];
    if (createStage.includes(row.curnNodeNumber)) {
      return row.osaMuId == curMuId;
    }
    if (row.curnNodeNumber == 'AUDIT_WEEK_PLAN_ONE_NODE') {
      return row.directLeader?.id == curMuId;
    }
    if (auditList.includes(row.curnNodeNumber)) {
      return true;
    }
    return false;
  }
  if (history.location.query.busiId) {
    return true;
  }
};
const isShowReturnBtn = (row) => {
  if (row) {
    if (row.curnNodeNumber == 'AUDIT_WEEK_PLAN_ONE_NODE') {
      return row.directLeader?.id == curMuId;
    }
  }
  return false;
};
// 防抖标识
let timer = null;

const indicatorsKey = {
  DIR: 1,
  ITEM: 2,
};
/** 递归校验目录必须含有一个或多个指标 */
const indicatorsValidate = (list) =>
  list.every((item) => {
    if (item.type == indicatorsKey.DIR && !(item.childList?.length > 0)) {
      return false;
    }
    if (Array.isArray(item.childList)) {
      return indicatorsValidate(item.childList);
    }
    return true;
  });

const isDisabled = (curData) => {
  if (!curData.osaId) {
    return true;
  }
  if (!curData.curnNodeNumber) return false;
  if (
    curData &&
    ![
      'CREATE_WEEK_PLAN_NODE',
      'CONFIRM_WEEK_PLAN_NODE',
      'AUDIT_WEEK_PLAN_ONE_NODE',
    ].includes(curData.curnNodeNumber)
  )
    return true;
  if (history.location.query.osaId) return true;
  if (history.location.query.busiId) return true;
  if (!curData?.osaId) return true;
};

const createTemplate = ({
  performance,
  dispatch,
  osaBeginTime,
  osaEndTime,
  muId,
  empName,
  osaWeekPlanWeekOfMonth: osaWeekOfMonth,
  osaWeekPlanMonth: osaYearMonth,
  osaWeekPlanQuarter: osaYearQuarter,
  curYear,
}) => {
  let curData = performance.curOsaSettingData.rows ?? {};

  console.log('performance = ', performance);

  console.log('curData = ', curData);

  console.log('muId = ', muId);

  const [resultList, setResultList] = useState([]);

  const [refuseinfo, setRefuseinfo] = useState(null);

  const initPayload = {
    osaBeginTime,
    osaEndTime,
    osaType: OSATYPEKEY.WEEK,
    muId,
  };

  // 是否禁用当前表单
  let disabled = isDisabled(curData);

  if (curData.curnNodeNumber == 'CONFIRM_WEEK_PLAN_NODE') {
    disabled = undefined;
  }

  const auditResult = useRef(AUDITTYPE.SUCCESS);
  // 确认绩效
  const [editModalVisible, setEditModalVisible] = useState(false);

  useEffect(() => {
    if (muId) {
      init();
      dispatch({
        type: 'performance/getosaauditsystemdescribelist',
      }).then((list) => {
        if (list) {
          setResultList(
            list.map((item) => ({ label: item.value, value: item.id })),
          );
        }
      });
      return () => {
        dispatch({
          type: 'performance/save',
          payload: {
            curOsaSettingData: {
              rows: {},
            },
          },
        });
      };
    }
  }, [muId, osaBeginTime]);

  const clearCache = () => {
    dispatch({
      type: 'performance/save',
      payload: {
        osaProcessCache: {},
      },
    });
  };

  // 初始化数据
  const init = async () => {
    // console.log("切换。。。。。。。。。。。。。。。。。。");
    if (history.location.query.busiId) {
      curData = await dispatch({
        type: 'performance/getosaemployeedata',
        payload: {
          busiId: history.location.query.busiId,
        },
      }).then((res) => {
        if (res) {
          dispatch({
            type: 'performance/save',
            payload: {
              curOsaSettingData: {
                ...performance.curOsaSettingData,
                rows: {
                  ...res,
                },
              },
            },
          });
          return res;
        }
        return {};
      });
      // console.log("切换。。。。。。。。。。。。。。。。。。1");
    } else if (history.location.query.target != targetTYPE.edit) {
      // console.log("切换。。。。。。。。。。。。。。。。。。2");
      curData = (await GetData()) ?? curData;
    }
    if (curData.osaId) {
      dispatch({
        type: 'performance/getosaemployeeauditscoredata',
        payload: {
          osaId: curData.osaId,
        },
      });
      dispatch({
        type: 'performance/getosauserprocesslist',
        payload: {
          osaId: curData.osaId,
          todoBusiId: '',
        },
      }).then((res) => {
        console.log('refuseinfo==================1', refuseinfo);

        if (res) {
          res.osaProcessDataList.map((item) => {
            if (
              res.backNodeId != null &&
              item.processNodeId === res.backNodeId
            ) {
              console.log('请求拒绝接口了');
              dispatch({
                type: 'performance/getosaweekprocessrefuseinfo',
                payload: {
                  osaId: curData.osaId,
                  todoBusiId: '',
                },
              }).then((res) => {
                // console.log('getosaweekprocessrefuseinfo:curData', curData);
                if (res) {
                  setRefuseinfo(res);
                }
              });
            }
          });
        }
      });
    }
  };

  const setInitPayloadCurYear = (res) => {
    if (curYear) {
      initPayload.osaYear = curYear;
    } else {
      if (osaEndTime) {
        let osaYear = moment(osaEndTime).year();
        initPayload.osaYear = osaYear;
      }
    }

    if (res) {
      let moreDate = {
        // ...getWeekOfMorY(osaBeginTime),
        ...{ osaWeekOfMonth, osaYearMonth, osaYearQuarter },
      };
      Object.assign(initPayload, moreDate);
      res.osaName = `${res.userInfo?.value || ''}${initPayload.osaYear}年${
        initPayload.osaYearMonth
      }月第${initPayload.osaWeekOfMonth}周周计划`;
      if (performance.curOsaSettingData) {
        performance.curOsaSettingData.osaName = res.osaName;
      }
    }
  };

  /** 初始化周计划 */
  const initWeekData = () => {
    // const { osaWeekOfMonth, osaWeekOfYear, osaYear, osaYearMonth } =
    //   getWeekOfMorY(osaBeginTime);
    let moreDate = {
      ...getWeekOfMorY(osaBeginTime),
      ...{ osaWeekOfMonth, osaYearMonth, osaYearQuarter },
    };
    Object.assign(initPayload, moreDate);
    // initPayload.osaWeekOfMonth = osaWeekOfMonth;
    // initPayload.osaWeekOfYear = osaWeekOfYear;

    console.log('initPayload...osaEndTime', osaEndTime, 'curYear...', curYear);
    setInitPayloadCurYear();

    if (
      moreDate.osaWeekOfMonth &&
      initPayload.osaBeginTime &&
      initPayload.muId
    ) {
      dispatch({
        type: 'performance/initosaemployeedata',
        payload: initPayload,
      }).then((res) => {
        if (res) {
          dispatch({
            type: 'performance/save',
            payload: {
              curOsaSettingData: {
                ...performance.curOsaSettingData,
                rows: {
                  ...res,
                  osaName: `${res.userInfo?.value || ''}${
                    initPayload.osaYear
                  }年${initPayload.osaYearMonth}月第${
                    initPayload.osaWeekOfMonth
                  }周周计划`,
                  // title: curData.title,
                  // osaYearQuarter,
                },
              },
            },
          });
          handleSave({
            ...res,
            osaName: `${res.userInfo?.value || ''}${initPayload.osaYear}年${
              initPayload.osaYearMonth
            }月第${initPayload.osaWeekOfMonth}周周计划`,
          });
        }
      });
    }
  };

  /** 编辑或者新增进入调用 */
  const GetData = () => {
    console.log('GetData1111111111===============', curData);

    setRefuseinfo(null);
    if (initPayload.osaBeginTime && initPayload.muId) {
      return dispatch({
        type: 'performance/queryempweekplandata',
        payload: { ...initPayload, osaType: undefined },
      }).then((res) => {
        if (res) {
          console.log(
            'GetData1111111111===============res',
            performance.curOsaSettingData,
            res,
          );

          setInitPayloadCurYear(res);

          dispatch({
            type: 'performance/save',
            payload: {
              curOsaSettingData: {
                ...performance.curOsaSettingData,
                rows: {
                  ...res,
                  // title: curData.title,
                  // osaYearQuarter,
                },
              },
            },
          });
          return res;
        } else {
          dispatch({
            type: 'performance/save',
            payload: {
              curOsaSettingData: {},
            },
          });
          return Promise.resolve({});
        }
      });
    }
  };

  // 基本信息的附加项
  const infoParams = [
    {
      title: '考核方案名称',
      dataIndex: 'osaName',
      valueType: 'Input',
      required: '请输入考核名称',
      fieldProps: {
        disabled: true,
        placeholder: '请输入考核方案名称',
      },
    },
    {
      title: '考核对象',
      render() {
        return (
          <div>
            {curData?.title ??
              curData?.userInfo?.value ??
              history.location.query.deptName}
          </div>
        );
      },
    },
  ];

  // 驳回信息
  const rejectInfoParams = [
    {
      title: `${refuseinfo?.backUserName}:`,
      render() {
        return <div>{refuseinfo?.backUserRemark}</div>;
      },
    },
  ];

  // 修改绩效数据
  const handleSave = (value) => {
    // 保存dva当前的数据
    dispatch({
      type: 'performance/save',
      payload: {
        templateEditor: {
          ...curData,
          ...value,
        },
      },
    });
    // 后端保存数据
    dispatch({
      type: 'performance/updateosaemployeedata',
      payload: {
        ...curData,
        ...value,
        key: undefined,
        title: undefined,
      },
    });
  };

  // 修改需要通知的表单时调用
  const handleNotice = (value) => {
    if (value.osaTime) {
      const { osaWeekOfYear, osaYear } = getWeekOfMorY(value.osaTime[0]);
      Object.assign(value, {
        osaWeekOfYear,
        osaYear,
      });
      const [start, end] = value.osaTime;
      value.osaBeginTime = start.format('YYYY-MM-DD HH:mm:ss');
      value.osaEndTime = end.format('YYYY-MM-DD HH:mm:ss');
      // value.osaWeekOfYear = osaWeekOfYear;
      value.osaTime = undefined;
    }
    let rows = {
      ...curData,
      ...value,
    };

    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: {
          ...performance.curOsaSettingData,
          rows: {
            ...rows,
          },
        },
      },
    });
    // 防抖处理
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      const {
        osaBeginTime,
        osaEndTime,
        osaId,
        osaMuId,
        osaNoticeDaysBeforeAfter,
        osaWeekOfMonth,
        osaWeekOfYear,
      } = curData;
      let payload = {
        osaBeginTime,
        osaEndTime,
        osaId,
        osaMuId,
        osaNoticeDaysBeforeAfter,
        osaWeekOfMonth,
        osaWeekOfYear,
      };
      dispatch({
        type: 'performance/updateosaemployeenoticedata',
        payload,
      });
    }, 1500);
  };

  const baseCloumns = [
    // {
    //   title: '考核周期',
    //   valueType: 'RadioGroup',
    //   fieldProps: {
    //     disabled: true,
    //     value: curData?.osaWeekType,
    //     options: [
    //       {
    //         label: '周',
    //         value: 1,
    //       },
    //       {
    //         label: '季度',
    //         value: 3,
    //       },
    //     ],
    //   },
    // },
    {
      title: '考核有效期',
      valueType: 'DatePickerRangePicker',
      // dataIndex: 'osaTime',
      fieldProps: {
        disabled,
        onChange: (val) => handleNotice({ osaTime: val }),
        value: [moment(curData.osaBeginTime), moment(curData.osaEndTime)],
        placeholder: ['开始时间', '结束时间'],
        disabledDate: (current) =>
          current <
            moment(curData.osaBeginTime).startOf('week').add(1, 'day') ||
          current > moment(curData.osaBeginTime).endOf('week').add(1, 'day'),
      },
    },
    // {
    //   title: '自动分发时间',
    //   valueType: 'InputNumber',
    //   render() {
    //     return (
    //       <AutoSend
    //         disabled={disabled}
    //         onChange={(val) => handleNotice({ osaNoticeDaysBeforeAfter: val })}
    //         value={curData?.osaNoticeDaysBeforeAfter}
    //       />
    //     );
    //   },
    //   // fieldProps: {
    //   //   disabled,
    //   //   onChange: (val) => handleNotice({ osaNoticeDaysBeforeAfter: val }),
    //   //   value: curData?.osaNoticeDaysBeforeAfter,
    //   //   placeholder: '自动分发时间',
    //   //   stepType: 'inside',
    //   // },
    // },
    {
      title: '计分规则',
      valueType: 'Select',
      extra:
        '在既定时间内完成目标，且有多付出、多贡献、有特别亮点的项目则视情况给予附加分1-20分',
      fieldProps: {
        value: curData?.osaScoreRule,
        placeholder: '请选择计分规则',
        disabled,
        options: [
          {
            label: '满分100分制（可向上浮动20%）',
            value: 1,
          },
        ],
      },
    },
  ];

  // 评分人设置保存
  const graderChange = (val) => {
    let payload = val.osaScoreList;
    let rows = {
      ...curData,
      ...val,
    };
    // dispatch({
    //   type: 'performance/save',
    //   payload: {
    //     templateEditor,
    //   },
    // });
    dispatch({
      type: 'performance/save',
      payload: {
        curOsaSettingData: {
          ...performance.curOsaSettingData,
          rows: {
            ...rows,
          },
        },
      },
    });
    dispatch({
      type: 'performance/updateosaemployeescore',
      payload,
    });
  };

  /** 确认绩效按钮文案 */
  const toNextNodeBtn = () => {
    let textObj = {
      WEEK_PLAN_BEGIN_NODE: '创建周计划',
      CREATE_WEEK_PLAN_NODE: '创建周计划',
      CONFIRM_WEEK_PLAN_NODE: '确认周计划',
      AUDIT_WEEK_PLAN_ONE_NODE: '审批通过',
      AUDIT_WEEK_PLAN_TWO_NODE: '审批通过',
      AUDIT_WEEK_PLAN_THREE_NODE: '审批通过',
      HR_PRE_CONFIRM_WEEK_PLAN_NODE: '审批通过',
    };
    return textObj[curData.curnNodeNumber] || '创建周计划';
  };

  const goback = () => {
    if (!history.goBack()) {
      history.replace('/usercenter/business');
    }
  };

  const hasEq100 = () => {
    const countScore = performance.osaIndicators.reduce(
      (pre, cur) => cur.weight + pre,
      0,
    );
    if (countScore != 100) {
      message.error(
        `指标总权重超过100或者少于100，当前指标总分数：${countScore}`,
      );
      return false;
    }
    let filterItem = floatIndicators(performance.osaIndicators).filter(
      (item) => item.osaIndexRemainWeight,
    );
    if (filterItem.length > 0) {
      let name = filterItem.map((item) => item.osaIndexCatgName).join();
      message.error(`${name}指标剩余权重未分配完成`);
      return false;
    }
    return true;
  };

  let list =
    performance.osaProcessCache[curData.osaId]?.osaProcessDataList ?? [];
  // console.log(performance.osaProcessCache[curData.osaId]);
  let backNodeId = performance.osaProcessCache[curData.osaId]?.backNodeId;
  //  console.log(backNodeId)
  // let backNodeId = ''
  let curIndex = list.findIndex((item) => item.curnNode);
  if (performance.osaProcessCache[curData.osaId]?.processOver) {
    curIndex = list.length;
  }
  let dots = (index) => {
    if (backNodeId != null && list[index].processNodeId === backNodeId)
      return { dot: <CloseCircleFilled style={{ color: '#f95520' }} /> };
    else if (index < curIndex) return { dotColor: '#0DE894' };
    else if (index == curIndex) return { dotColor: '#4389f9' };
    else return {};
  };

  // console.log('currentdata : ', curData)

  // 确认绩效
  const editModalSave = (value) => {
    /** 通过/拒绝原因 */
    let auditRemarkList = [
      ...(value.auditRemarkList ?? []),
      value.auditRemark,
    ]?.map((txt) => {
      let SUCCESSID = 88888888;
      let ERRORID = 99999999;

      let auditRmkDescribe =
        resultList.find((item) => item.value == txt)?.label || txt;
      let auditRmkDescribeId =
        resultList.find((item) => item.value == txt)?.value ??
        (auditResult.current == AUDITTYPE.SUCCESS ? SUCCESSID : ERRORID);
      let auditRmkType = resultList.some((item) => item.value == txt) ? 1 : 2;
      return {
        auditRmkDescribe,
        auditRmkDescribeId,
        auditRmkType,
      };
    });
    let payload = {
      ...value,
      auditRemarkList,
      osaId: curData?.osaId,
      auditResult: auditResult.current,
    };
    dispatch({
      type: 'performance/auditosaprocess',
      payload,
    }).then((res) => {
      if (res) {
        setEditModalVisible(false);
        clearCache();

        if (backNodeId != null) {
          window.location.reload();
          backNodeId == null;
          // editModalSave('')
        } else if (!history.goBack()) {
          history.replace('/usercenter/business');
        }
      }
    });
  };

  // 顶部按钮
  const HeaderButton = (
    <div className="fixed-right">
      {!curData?.osaId && (
        <Button type="primary" onClick={initWeekData}>
          初始化周计划
        </Button>
      )}

      {/* {(isNeedEdit) && (
        <Button type="primary" onClick={GetData}>
        编辑
        </Button>
      )} */}

      {isShowConfirmBtn(curData, list) && (
        <Button
          type="primary"
          onClick={() => {
            if (!indicatorsValidate(performance.osaIndicators)) {
              message.error('指标目录应含有指标');
              return;
            }
            if (
              curData.curnNodeNumber == 'CONFIRM_WEEK_PLAN_NODE' &&
              !hasEq100()
            ) {
              // console.log(1111111111)
              return;
            }

            if (curData.osaId != null && backNodeId != null) {
              editModalSave('');
            } else {
              setEditModalVisible(true);
            }

            auditResult.current = AUDITTYPE.SUCCESS;
          }}
        >
          {toNextNodeBtn()}
        </Button>
      )}
      {isShowReturnBtn(curData) && (
        <Button
          type="danger"
          onClick={() => {
            setEditModalVisible(true);
            auditResult.current = AUDITTYPE.ERROR;
          }}
        >
          驳回
        </Button>
      )}
      <Button type="primary" onClick={() => goback()}>
        返回
      </Button>
    </div>
  );
  // 确认绩效表单
  const edtaFormItem = [
    auditResult.current == AUDITTYPE.ERROR
      ? {
          label: '原因',
          name: 'auditRemarkList',
          type: 'Select',
          props: {
            mode: 'tags',
            options: resultList,
            placeholder: '请输入原因，按enter确定',
          },
        }
      : null,
    {
      label: '备注',
      name: 'auditRemark',
      type: 'TextArea',
      props: {
        autoSize: { minRows: 2, maxRows: 6 },
        placeholder: '备注',
      },
    },
  ].filter(Boolean);

  // let backNodeId = performance.osaProcessCache[curData.osaId]?.backNodeId;
  // if(curData.osaId != null && backNodeId != null)
  // {
  //   performance.osaProcessCache[curData.osaId]?.backNodeId == null
  //   editModalSave('')
  // }

  return (
    <div className="create-template-page" key={curData?.osaId}>
      {!history.location.query?.osaId && HeaderButton}
      <Typography.Title level={4}>
        {curData?.osaId ? (
          `${
            empName || curData?.title || curData?.userInfo?.value || ''
          }周计划设置`
        ) : (
          <div style={{ color: 'red' }}>请初始化周计划</div>
        )}
      </Typography.Title>
      <CollapseCard
        title="基本设置"
        cloumns={Template.INFO.default({
          disabled,
          target: curData,
          arr: infoParams,
        })}
        defaultValue={curData}
        onSave={handleSave}
      />
      {curData?.osaId && (
        <CollapseCard title="周计划内容">
          <Template.INDICATORS.default
            dispatchApi={dispatchApi}
            disabled={disabled}
            osaWeekType={1}
            idKey="osaId"
            idValue={curData?.osaId}
          />
        </CollapseCard>
      )}
      <CollapseCard
        title="基本信息"
        onSave={handleNotice}
        cloumns={baseCloumns}
        defaultValue={curData}
      />

      {refuseinfo &&
        refuseinfo?.backUserRemark &&
        refuseinfo?.backUserRemark != 'null;' && (
          <CollapseCard
            visible="v"
            title="驳回信息"
            cloumns={Template.INFO.default({
              disabled,
              target: curData,
              arr: rejectInfoParams,
            })}
          />
        )}

      <CollapseCard
        title={
          <>
            流程图
            <span style={{ color: '#999', fontSize: 12 }}>
              （查看目前处于哪个环节)
            </span>
          </>
        }
      >
        <Timeline>
          {list.map((item, index) => (
            <Timeline.Item
              key={index}
              content={item.processDescribe}
              // dot={<Icon type="successFill" size="14" fill="#4389F9" />}
              {...dots(index)}
            />
          ))}
        </Timeline>
      </CollapseCard>
      {/* <CollapseCard title="评分人设置">
        <Template.GRADER.default
          onSave={graderChange}
          disabled={disabled}
          defaultValue={curData?.osaScoreList}
        />
      </CollapseCard> */}
      {/* <Template.OFFTIME.default
        onSave={handleSave}
        disabled={disabled}
        defaultValue={curData?.osaScoreEndDaysBeforeAfter}
      /> */}
      <CollapseCard title="审核流程设置">
        <Template.PROCESS.default
          disabled={disabled}
          defaultValue={curData?.osaAuditList}
        />
      </CollapseCard>
      {/* <Template.VALIDITY.default defaultValue={curData} /> */}
      <ModalFormPro
        width={600}
        visible={editModalVisible}
        edtaFormItem={edtaFormItem}
        onSave={editModalSave}
        onCancel={() => {
          setEditModalVisible(false);
        }}
      />
    </div>
  );
};

export default connect(({ performance }) => {
  return { performance };
})(createTemplate);
