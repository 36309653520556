import Jsonform from '@/components/JsonForm';
import { MessageBox, Dropdown, Button, message } from 'kenshin';
import { useEffect, useRef } from 'react';
import { useSelector, history, useDispatch } from 'umi';
import TablePro from '@/components/TablePro';
import ChooseIndicators from '../chooseIndicators';
import { useState } from 'react';
import {
  dirCloumns,
  itemCloumns,
  monthCloumns,
} from '../../../indicators/_cloumns';
import ModalFormPro from '@/components/ModalFormPro';
import EllipsisTooltip from '@/components/EllipsisTooltip';
import EditForExcel from '../../editForExcel';
import ChooseHistoryOSA from '../../ChooseHistoryOSA';
import { deepClone, flat, downloadXlsx, getMonthName } from '@/_util/util';
import PreviewIndicators from '../../PreviewIndicators';

const DIR = 1; // 目录
const ITEM = 2; // 指标

const defaultEdshowData = () => ({
  osaIndexItemModel: 1,
  osaIndexItemScoreMethod: 1,
  osaIndexItemActualValueSource: 1,
  osaIndexItemGoalValueSource: 1,
});

export function getAllTreeKeys(tree) {
  if (!Array.isArray(tree)) return [];
  let keys = [];
  return flat(
    tree.map((row) => {
      if (row) {
        if (row.childList) {
          let arr = getAllTreeKeys(row.childList);
          return [...arr, `${row.type}-${row.id}`];
        }
      }
      return `${row.type}-${row.id}`;
    }),
    2,
  );
}

/** 查找节点 */
function findNodeById(key, nodes) {
  for (let node of nodes) {
    // 只找目录
    if (`${node.type}-${node.id}` === key) {
      return node;
    }
    if (node.childList) {
      let result = findNodeById(key, node.childList);
      if (result) {
        return result;
      }
    }
  }

  return null;
}

function setTreeWeight(tree) {
  if (Array.isArray(tree)) {
    tree.forEach((item) => {
      if (Array.isArray(item.childList)) {
        setTreeWeight(item.childList);
        let osaIndexRemainWeight =
          item.childList?.reduce((pre, cur) => pre + cur.weight, 0) ?? 0;
        item.osaIndexRemainWeight = Math.abs(
          item.weight - osaIndexRemainWeight,
        );
      }
    });
  }
  return [];
}
const dispatchType = {
  GETOSAMONTHSTRAGET: 'performance/getosamonthstraget',
  ADDUPDATEOSAMONTHSTRAGET: 'performance/addupdateosamonthstraget',

  GETOSACHANGEMONTHSTRAGET: 'performance/getosachangemonthstraget',
  ADDUPDATEOSACHANGEMONTHSTRAGET: 'performance/addupdateosachangemonthstraget',

  COPYHISTORY: 'performance/addupdateosamonthstraget',
  GETTREE: 'performance/getosatemplateindextree',
  GETDIRTREE: 'performance/getosatemplateindexcatgtree',
  UPDATEDIR: 'performance/updateosatemplateindexcatgdata',
  UPDATEITEM: 'performance/updateosatemplateindexitemdata',
  DELETEDIR: 'performance/deleteosatemplateindexcatgdata',
  DELETEITEM: 'performance/deleteosatemplateindexitemdata',
  ADDTREE: 'performance/addosatempindexdata',
  MOVEDIR: 'performance/moveosatemplateindexcatgdata',
  MOVEITEM: 'performance/moveosatemplateindexitemdata',
  IMPOERSHEET: 'performance/importosatemplateindexdata',
  GETHISTORYOSAMONTHSTRAGET: 'performance/gethistoryosamonthstraget',
};

let defaultEditMonthContent = [
  { osaIndexCatgName: '', code: '1', childList: '' }, //osaIndexItemName
  { osaIndexCatgName: '', code: '2', childList: '' },
  { osaIndexCatgName: '', code: '3', childList: '' },
];

const indicators = ({
  dispatchApi = dispatchType,
  // id参数名
  tableIdkey,
  idKey = 'osaTemplateId',
  // 其他id，例如绩效id，默认使用模板id
  idValue,
  newIdValue,
  disabled,
  osaWeekType,
  showPublicTemplate = true,
} = {}) => {
  const dispatch = useDispatch();
  const performance = useSelector(({ performance }) => performance);
  const loading_ = useSelector(({ loading }) => loading.effects);
  const [chooseData, setChooseData] = useState(null);
  const [sheetVisible, setSheetVisible] = useState(false); // 设置excel弹窗状态
  const [historyVisible, setHistoryVisible] = useState(false); // 设置历史绩效弹窗选择状态
  const [chooseVisible, setChooseVisible] = useState(false);

  // 控制展开行
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  /** 模板id */
  const osaTemplateId =
    newIdValue ?? idValue ?? history.location.query.osaTemplateId;

  // 目录
  const [editDirVisible, setEditDirVisible] = useState(false);
  // 指标
  const [editItemVisible, setEditItemVisible] = useState(false);
  // 弹窗数据
  const [editModalData, setEditModalData] = useState(null);
  // 剩余权重
  const [maxWeight, setMaxWeight] = useState(100);
  // 设置指标编辑的回显参数
  const [editShowData, setEditShowData] = useState(defaultEdshowData());

  // 编辑月度策略
  const [editMonthContent, seteditMonthContent] = useState(
    defaultEditMonthContent,
  );
  //编辑前的月度策略
  const [beforeEditMonthContent, setBeforeEditMonthContent] = useState([]);

  //当前编辑的策略
  const [editMonthIndex, setEditMonthIndex] = useState(0);

  const setOsaIndexCatgName = (content, isClear = true) => {
    let osaYearQuarter = history.location.query.osaYearQuarter;
    if (osaYearQuarter) {
      const lastChar = osaYearQuarter[osaYearQuarter.length - 1]; // 获取最后一个字符
      content.map((item, index) => {
        item.osaIndexCatgName = getMonthName(`${lastChar}-${index + 1}`);
        if (isClear) {
          item.childList = '';
        }
      });
    }
    console.log('setOsaIndexCatgName...', content);

    return content;
  };

  useEffect(() => {
    console.log(
      'GETOSAMONTHSTRAGET===============:11',
      performance.monthStrategy,
    );
    if (performance.monthStrategy && performance.monthStrategy.length > 0) {
      seteditMonthContent(performance.monthStrategy);
    } else {
      setOsaIndexCatgName(defaultEditMonthContent);

      seteditMonthContent(defaultEditMonthContent);
    }
  }, [performance.monthStrategy]);

  useEffect(() => {
    console.log('editMonthContent===============:11', editMonthContent);
  }, [editMonthContent]);

  useEffect(() => {
    console.log(
      'GETOSAMONTHSTRAGET===============:22',
      performance.osaIndicators,
    );
  }, [performance.osaIndicators]);

  useEffect(() => {
    // console.log("dispatchApi.GETTREE===============tableIdkey:",tableIdkey,"idKey:",idKey);
    if (osaTemplateId) {
      getDirTree();
      performance.monthStrategy = undefined;
      dispatch({
        type: newIdValue
          ? dispatchApi.GETOSACHANGEMONTHSTRAGET
          : dispatchApi.GETOSAMONTHSTRAGET,
        payload: { [tableIdkey || idKey]: osaTemplateId },
      });
    }
  }, [history.location.query.osaTemplateId, idValue]);

  const dirRef = useRef('');
  const itemRef = useRef('');

  const init = () => {
    setEditDirVisible(false);
    setEditItemVisible(false);
    setEditModalData(null);
    setEditShowData(defaultEdshowData());
    setMaxWeight(100);
    console.log('beforeEditMonthContent...', beforeEditMonthContent);
    seteditMonthContent(beforeEditMonthContent);
  };
  const treeSelectClear = () => {
    let totalWeight = performance.osaIndicators.reduce(
      (pre, cur) => pre + cur.weight,
      0,
    );
    setMaxWeight(100 - totalWeight);
  };
  // 目录弹窗配置
  const monthCloumn = monthCloumns({
    dirTree: performance.dirTree,
    maxWeight,
    setMaxWeight,
    onClear: treeSelectClear,
    showRemark: !showPublicTemplate,
    contents: editMonthContent[editMonthIndex]?.childList,
    curIndex: editMonthIndex,
    onAdd: () => {
      let arr = editMonthContent[editMonthIndex]?.childList;
      let newArr = [
        ...arr,
        { code: `${editMonthIndex + 1}.${arr.length + 1}` },
      ];
      editMonthContent[editMonthIndex].childList = newArr;
      seteditMonthContent([...editMonthContent]);
    },
    onDelete: (index) => {
      seteditMonthContent((prevState) => {
        let strages = prevState[editMonthIndex]?.childList;
        let newArr = [...strages.slice(0, index), ...strages.slice(index + 1)];
        editMonthContent[editMonthIndex].childList = newArr;
        // console.log("prevState...", editMonthContent);
        return [...editMonthContent];
      });
    },
    onChange: (strages) => {
      let newArr = [...strages];
      editMonthContent[editMonthIndex].childList = newArr;

      editMonthContent[editMonthIndex].weight = newArr.reduce(
        (acc, obj) => acc + parseInt(obj.weight),
        0,
      );
      editMonthContent[editMonthIndex].osaIndexRemainWeight =
        100 - editMonthContent[editMonthIndex].weight;

      console.log('onChange...', editMonthContent);
      seteditMonthContent([...editMonthContent]);
    },
  });
  // 指标弹窗配置
  const itemEditCloumn = itemCloumns({
    dirTree: performance.dirTree,
    editShowData,
    setEditShowData,
    maxWeight,
    setMaxWeight,
    onClear: treeSelectClear,
    showRemark: !showPublicTemplate,
  });

  // 获取目录树
  const getDirTree = () => {
    console.log(
      'dispatchApi.GETDIRTREE===============tableIdkey:',
      tableIdkey,
      'idKey:',
      idKey,
    );
    // dispatch({
    //   type: dispatchApi.GETDIRTREE,
    //   payload: {
    //     [tableIdkey || idKey]: osaTemplateId,
    //   },
    // });
  };

  // 点击编辑
  const showEditModal = (row, type, pObj) => {
    if (type == DIR) {
      // if(pId) setEditModalData({osaIndexCatgPantId:pId});
      // console.log("dirRef.current",dirRef.current.form?.setFieldsValue);
      pObj &&
        dirRef.current.form?.setFieldsValue({ osaIndexCatgPantId: pObj.id });
      // 目录
      setEditDirVisible(true);
    } else if (type == ITEM) {
      // if(pId) setEditModalData({osaIndexCatgId:pId});
      pObj && itemRef.current.form?.setFieldsValue({ osaIndexCatgId: pObj.id });
      // 指标
      setEditItemVisible(true);
      row && setEditShowData(row); // 设置展开项
    }
    if (row) {
      // 剩余的权重
      let allSurplusCount =
        100 -
          performance.osaIndicators.reduce((pre, cur) => pre + cur.weight, 0) ||
        0;
      let prant = findNodeById(
        `1-${row.osaIndexCatgPantId || row.osaIndexCatgId}`,
        performance.osaIndicators,
      );
      let parentRemainWeight = prant
        ? prant.osaIndexRemainWeight
        : 0 + allSurplusCount;
      setMaxWeight(parentRemainWeight + row.weight); // 设置展开项
    } else {
      treeSelectClear();
      if (pObj?.id) {
        setMaxWeight(pObj.osaIndexRemainWeight);
      }
    }
    // if(row){
    setEditModalData(row || editShowData);
    // }

    setEditDirVisible(true);
  };

  /** 弹窗保存 */
  const handleSave = (data = undefined) => {
    let hasEdit = true;
    let payload = {};
    payload.osaId = osaTemplateId;
    if (data) {
      payload.monthStrategy = JSON.stringify(data);
    } else {
      payload.monthStrategy = JSON.stringify(editMonthContent);
    }

    console.log('handleSave12=========================', payload, data);

    let type = newIdValue
      ? dispatchApi.ADDUPDATEOSACHANGEMONTHSTRAGET
      : dispatchApi.ADDUPDATEOSAMONTHSTRAGET;

    dispatch({
      type,
      hasEdit,
      payload,
    }).then((res) => {
      if (res) {
        // init();
        // getDirTree();
        setEditDirVisible(false);
      }
    });
  };

  /** 弹窗保存 */
  const handleSave_ = (data) => {
    if (data) {
      editMonthContent[editMonthIndex].childList = data.childList;
      editMonthContent[editMonthIndex].osaIndexCatgName = data.osaIndexCatgName;
    }

    seteditMonthContent(editMonthContent);

    let hasEdit = true;
    let payload = {};
    payload.osaId = osaTemplateId;
    payload.monthStrategy = JSON.stringify(editMonthContent);

    console.log('handleSave12=========================', editMonthContent);

    let type = newIdValue
      ? dispatchApi.ADDUPDATEOSACHANGEMONTHSTRAGET
      : dispatchApi.ADDUPDATEOSAMONTHSTRAGET;

    dispatch({
      type,
      hasEdit,
      payload,
    }).then((res) => {
      if (res) {
        // init();
        // getDirTree();
        setEditDirVisible(false);
      }
    });
  };

  const delItem = (row) => {
    MessageBox.confirm({
      title: '确认操作',
      content: '确认清空？删除后无法恢复',
      onOk: () => {
        let index = setCurrentEditMonthContent(row);
        console.log('setEditMonthIndex3', index);
        editMonthContent[index].childList = '';
        seteditMonthContent(editMonthContent);
        handleSave();
      },
    });
  };

  const setCurrentEditMonthContent = (row) => {
    const index = editMonthContent.findIndex(
      (item) => item.osaIndexCatgName === row.osaIndexCatgName,
    );
    console.log('setEditMonthIndex2', row, index);
    setEditMonthIndex(index);
    return index;
  };

  const columns = [
    {
      dataIndex: 'osaIndexCatgName',
      title: '时间',
      width: '10%',
      render: (val) => val,
    },

    {
      dataIndex: 'childList',
      title: '月度策略',
      width: '60%',
      render: (val) => {
        return (
          <>
            {/* {val.map((item, index) => (
              <div key={index}>
                {' '}
                {`${index + 1}、${
                  item.osaIndexItemName ? item.osaIndexItemName : ''
                } - (${item.weight ? item.weight : ''}%)`}
              </div>
            ))} */}

            <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
              {val}
            </div>
          </>
        );
      },
    },

    {
      dataIndex: 'weight',
      title: '权重',
      width: '10%',
      render: (val) => (val ? `${val}%` : '-'),
    },
    {
      dataIndex: 'osaIndexRemainWeight',
      title: '剩余权重',
      width: '10%',
      render: (val) => (val != undefined ? `${val}%` : '-'),
    },
    !showPublicTemplate
      ? {
          dataIndex: 'remark',
          title: '备注',
          width: '10%',
          render: (val) => (val != undefined ? `${val}` : '-'),
        }
      : {},
    ...(() => {
      if (!disabled) {
        return [
          {
            title: '操作',
            width: '10%',
            key: 'options',
            render: (row) => {
              return (
                <>
                  {/* {row.type == 1 && (
                    <Dropdown
                      menu={dropdownMenu(row)}
                      trigger="click"
                      disabled={disabled}
                    >
                      <Button type="text">新增</Button>
                    </Dropdown>
                  )}
                  {row.type == 1 && (
                    <Button
                      type="text"
                      onClick={() => {
                        setChooseVisible(true);
                        setChooseData(row);
                      }}
                    >
                      模板
                    </Button>
                  )} */}
                  <Button
                    type="text"
                    onClick={() => {
                      showEditModal(row, row.type);
                      setBeforeEditMonthContent(
                        JSON.parse(JSON.stringify(editMonthContent)),
                      );

                      setCurrentEditMonthContent(row);
                    }}
                  >
                    编辑
                  </Button>
                  <Button
                    type="text"
                    style={{ color: 'red' }}
                    onClick={() => {
                      setCurrentEditMonthContent(row);
                      delItem(row);
                    }}
                  >
                    清空
                  </Button>
                </>
              );
            },
          },
        ];
      }
      return [];
    })(),
  ];

  // 选择指标之后返回一颗新树
  const chooseSave = (tree) => {
    setTreeWeight(tree);
    let countWeights = tree.reduce((pre, cur) => pre + cur.weight, 0);
    let rootWeight =
      performance.osaIndicators?.reduce((pre, cur) => pre + cur.weight, 0) ?? 0;
    let rootRemainWeigh = Math.abs(100 - rootWeight);

    if (showPublicTemplate) {
      //是否判断权重
      if (chooseData) {
        if (countWeights > chooseData?.osaIndexRemainWeight) {
          message.error(
            `当前选择的指标权重超过剩余权重：${chooseData.osaIndexRemainWeight}%!`,
          );
          return;
        }
      } else if (countWeights > rootRemainWeigh) {
        message.error(`当前选择的指标权重超过剩余权重：${rootRemainWeigh}%!`);
        return;
      }
    }

    dispatch({
      type: dispatchApi.ADDTREE,
      payload: {
        indexDataJson: JSON.stringify(tree),
        [idKey]: osaTemplateId,
        indexPantId: chooseData?.id,
      },
    }).then((res) => {
      if (res) {
        setChooseVisible(false);
        setChooseData(null);
        getDirTree();
      }
    });
  };

  const onDrop = (startId, endId, sortType) => {
    if (disabled) {
      return;
    }
    if (startId == endId) {
      return;
    }
    let start = findNodeById(startId, performance.osaIndicators); // 拖拽项
    let end = findNodeById(endId, performance.osaIndicators); // 目标
    if (start && end) {
      let code = end.code; // 目标编码
      let sort = end.sort; // 目标排序
      let osaIndexCatgId = end.osaIndexCatgId ?? end.osaIndexCatgPantId; // 目标上级目录id
      let startCatgId = start.osaIndexCatgId ?? start.osaIndexCatgPantId; // 目标上级目录id

      let startCodeArr = start.code.split('.');
      let endCodeArr = code.split('.');
      // 处理跨级，拖拽code小于目标code的情况
      if (startCodeArr.length < endCodeArr.length) {
        for (let i = 0; i < startCodeArr.length; i++) {
          if (+startCodeArr[i] < +endCodeArr[i]) {
            +endCodeArr[i]--;
          }
        }
        code = endCodeArr.join('.');
      }
      // 目标上级目录
      let parent = findNodeById(
        `${DIR}-${osaIndexCatgId}`,
        performance.osaIndicators,
      );
      // 假如移入的目录大于目标上级目录的剩余权重，不通过
      if (
        osaIndexCatgId != startCatgId &&
        parent?.osaIndexRemainWeight < start.weight
      ) {
        message.error('权重超过剩余权重！');
        return;
      }

      let type = dispatchApi.MOVEDIR;
      let payload = {
        ...start,
        code,
        sort,
        osaIndexCatgPantId: osaIndexCatgId,
        childList: null,
      };

      if (start.type == ITEM) {
        // 假如拖拽的是指标
        type = dispatchApi.MOVEITEM;
        payload = {
          ...start,
          code,
          sort,
          osaIndexCatgId,
        };
      }

      // 单独处理osa考核的数据
      if (idKey == 'osaId') {
        payload.osaId = idValue;
      }
      if (idKey == 'osaUserId') {
        payload.osaUserId = idValue;
      }
      dispatch({
        type,
        payload,
      }).then((res) => {
        if (res) {
          getDirTree();
        }
      });
    }
  };

  // 下拉菜单
  const dropdownMenu = (row) => (
    <Dropdown.Menu>
      <Dropdown.Item
        disabled={disabled}
        onClick={() => showEditModal(null, DIR, row)}
      >
        新增目录
      </Dropdown.Item>
      <Dropdown.Item
        disabled={disabled}
        onClick={() => showEditModal(null, ITEM, row)}
      >
        新增指标
      </Dropdown.Item>
    </Dropdown.Menu>
  );

  const extra = () => (
    <>
      {/* <Dropdown menu={dropdownMenu()} trigger="click" disabled={disabled}>
        <Button type="primary">新增</Button>
      </Dropdown>
      <Button
        style={{ marginLeft: 8 }}
        disabled={disabled}
        onClick={() => {
          setChooseVisible(true);
          setChooseData(null);
        }}
      >
        添加指标模板
      </Button> */}
      {/* <Button
        onClick={() => {
          setExpandedRowKeys(
            expandedRowKeys?.length == 0
              ? getAllTreeKeys(performance.osaIndicators)
              : [],
          );
        }}
      >
        {expandedRowKeys?.length == 0 ? '全部展开' : '全部折叠'}
      </Button> */}
      {/* <Button
        style={{ marginLeft: 8 }}
        type="success"
        disabled={disabled}
        onClick={() => {
          console.log('Excel编辑...', editMonthContent);
          setSheetVisible(true);
          setChooseData(null);
        }}
      >
        Excel编辑
      </Button> */}
      {dispatchApi.COPYHISTORY && (
        <Button
          style={{ marginLeft: 8 }}
          type="success"
          disabled={disabled}
          onClick={() => {
            setHistoryVisible(true);
            setChooseData(null);
          }}
        >
          使用历史月度策略
        </Button>
      )}
      {/* <Button
        style={{ marginLeft: 8 }}
        disabled={disabled}
        onClick={() => {
          dispatch({
            type: 'performance/getosauserindextree',
          });
        }}
      >
        查询
      </Button> */}
    </>
  );

  const SheetSave = (value) => {
    console.log('SheetSave...', value);

    // handleSave(value);
    setSheetVisible(false);
    // dispatch({
    //   type: dispatchApi.IMPOERSHEET,
    //   payload: {
    //     [idKey == 'osaUserId' ? 'osaId' : idKey]: osaTemplateId,
    //     indexDataJson: JSON.stringify(value),
    //   },
    // }).then(async (res) => {
    //   if (res) {
    //     setSheetVisible(false);
    //     getDirTree();
    //     // await dispatch({
    //     //   type: 'performance/getosauserindextree',
    //     // });
    //     // await dispatch({
    //     //   type: 'performance/getosauserindextree',
    //     // });
    //   }
    // });
  };

  const historySave = (history) => {
    console.log('historySave...', history, JSON.parse(history.monthStrategy));
    if (history) {
      let content = JSON.parse(history.monthStrategy);
      setOsaIndexCatgName(content, false);

      seteditMonthContent(content);

      console.log('setOsaIndexCatgName...22', content);

      handleSave(content);
    }

    setHistoryVisible(false);
    // dispatch({
    //   type: dispatchApi.COPYHISTORY,
    //   payload: {
    //     historyOsaId,
    //     curnOsaId: idValue,
    //   },
    // }).then((res) => {
    //   if (res) {
    //     getDirTree();
    //     setHistoryVisible(false);
    //     setChooseData(null);
    //   }
    // });
  };

  const curData = performance.curOsaSettingData.rows ?? {};
  const disabledExtra = () => {
    if (curData) {
      return (
        <>
          {/* <PreviewIndicators row={curData} type="primary" />
          <Button
            onClick={() => {
              setExpandedRowKeys(
                expandedRowKeys?.length == 0
                  ? getAllTreeKeys(performance.osaIndicators)
                  : [],
              );
            }}
          >
            {expandedRowKeys?.length == 0 ? '全部展开' : '全部折叠'}
          </Button> */}
        </>
      );
    }
  };

  return (
    <>
      <TablePro
        // params={{ osaTemplateId: history.location.query.osaTemplateId }}
        // request="performance/getosatemplateindextree"
        rowKey={(row) => `${row.type}-${row.id}`}
        pagination={false}
        columns={columns}
        extra={!disabled ? extra : disabledExtra}
        // extra={extra}
        curColumns
        columnsOptions={false}
        drag={!disabled}
        // loading={loading_[dispatchApi.GETOSAMONTHSTRAGET]}
        onDrop={onDrop}
        childrenColumnName="childList22"
        dataSource={editMonthContent}
        expandable={{
          expandedRowKeys,
          onExpandedRowsChange: setExpandedRowKeys,
        }}
        scrollY={700}
      />
      <ChooseIndicators
        showPublicTemplate={showPublicTemplate}
        visible={chooseVisible}
        onSave={chooseSave}
        baseCode={performance.osaIndicators?.length}
        onCancel={() => {
          setChooseVisible(false);
          setChooseData(null);
        }}
      />
      <ModalFormPro
        width={800}
        title={editModalData?.id ? '编辑' : '新增'}
        visible={editDirVisible}
        edtaFormItem={monthCloumn}
        formData={editModalData}
        onSave={(val) => handleSave_(val)}
        ref={dirRef}
        onCancel={init}
      />
      {/* <ModalFormPro
        width={800}
        title={editModalData?.id ? '编辑' : '新增'}
        visible={editItemVisible}
        edtaFormItem={itemEditCloumn}
        formData={editModalData}
        ref={itemRef}
        onSave={(val) => handleSave(val, ITEM)}
        onCancel={init}
      /> */}
      <EditForExcel
        isCalculateWeight={false}
        visible={sheetVisible}
        loading={loading_[dispatchApi.IMPOERSHEET]}
        onCancel={() => {
          setSheetVisible(false);
          setChooseData(null);
        }}
        dataSource={editMonthContent} //performance.osaIndicators
        isMonth={true}
        onSave={SheetSave}
      />
      <ChooseHistoryOSA
        dispatchApi={dispatchApi.GETHISTORYOSAMONTHSTRAGET}
        osaWeekType={osaWeekType}
        visible={historyVisible}
        disabledId={idValue}
        onCancel={() => {
          setHistoryVisible(false);
          setChooseData(null);
        }}
        onSave={historySave}
      />
    </>
  );
};

export default indicators;
