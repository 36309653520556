import { changeNumToHan, flat } from '@/_util/util';
import { getQuarterTime } from '../_util';
import { Typography } from 'kenshin';
import './quarter.less';
import { useEffect, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useRef } from 'react';

/** 获取每周的开始时间（默认是周日，需+1天才是周一） */
const getWeekStart = (time) => {
  if (!time) return undefined;
  return moment(time).startOf('d').format('YYYY-MM-DD HH:mm:ss');
};
/** 获取时间 月月-日日格式字符串 */
const MD = (time) => moment(time).format('MM-DD');
const getTimeKey = (time) => {
  let startWeek = moment(time).startOf('month').week();
  let week = moment(time).week() - startWeek;
  let month = moment(time).month();
  let quarter = moment(time).quarter();
  let year = moment(time).year();
  let start = moment(time).startOf('week').add(1, 'd').format('YYYY-MM-DD');
  return [
    year,
    `${year}w${week}`,
    `${year}m${month + 1}`,
    `${year}q${quarter}`,
    start,
  ];
};
export default ({
  onChange,
  onYearChange,
  value,
  disabled,
  data,
  dispatch,
  curData,
}) => {
  const [selectTime, setSelectTime] = useState(value);
  const [expansion, setExpansion] = useState([...getTimeKey(selectTime)]);
  const [year, setYear] = useState(moment().year());

  const onceRef = useRef(true);

  /** 获取当前年的周计划日期 */
  const getWeekPlanDateTree = (osaWeekPlanYear) => {
    dispatch({
      type: 'performance/getyearweekplandatedata',
      payload: {
        osaWeekPlanYear,
      },
    }).then((res) => {});
  };

  useEffect(() => getWeekPlanDateTree(moment().year()), []);

  useEffect(() => {
    setSelectTime(value);
  }, [value]);
  useEffect(() => {
    if (data?.length > 0 && onceRef.current) {
      onceRef.current = false;
      setSelectTime(curData.osaBeginTime);
      setExpansion([...getTimeKey(curData.osaBeginTime)]);
      let quarter = moment().quarter();
      let weekArr = data[quarter - 1]?.osaWeekMonthList
        ?.map((item) => item.osaWeekDateList)
        .flat(5);
      let time = weekArr?.find((item) =>
        moment().isBetween(
          moment(item.osaWeekPlanBeginDate),
          moment(item.osaWeekPlanEndDate),
        ),
      );
      time && handleClick(time);
    }
  }, [data]);

  const switchExpansion = (e, name) => {
    e.stopPropagation();
    setExpansion((list) => {
      let i = list.indexOf(name);
      if (i != -1) {
        list.splice(i, 1);
      } else {
        list.push(name);
      }
      return [...list];
    });
  };

  const handleClick = (item) => {
    if (!item || disabled) return;
    const { osaWeekPlanBeginDate: start, osaWeekPlanEndDate: end } = item;
    setSelectTime(start);
    const osaBeginTime = moment(start)
      .startOf('d')
      .format('YYYY-MM-DD HH:mm:ss');
    const osaEndTime = moment(end).endOf('d').format('YYYY-MM-DD HH:mm:ss');
    onChange?.({ ...item, osaBeginTime, osaEndTime });
  };

  // 修改年份
  const changeYear = (yearDiff) => {
    let newYear = +year + yearDiff;
    setYear(newYear);
    getWeekPlanDateTree(newYear);
    onYearChange?.(newYear);
  };
  const getWeek = (month) => {
    return (
      <div>
        {month.map((item, index) => (
          <div
            key={index}
            className={`week dot ${
              getWeekStart(selectTime) == item.osaWeekPlanBeginDate
                ? 'select'
                : ''
            }`}
            onClick={(e) => handleClick(item)}
          >
            <span className="fb">{item.osaWeekPlanWeekOfMonth}周</span>(
            {MD(item.osaWeekPlanBeginDate)}~{MD(item.osaWeekPlanEndDate)})
          </div>
        ))}
      </div>
    );
  };

  const getMontn = (quarter) => {
    return (
      <div>
        {quarter.map((item) => (
          <div key={item.osaWeekPlanMonth} className="month">
            <div
              className={`month-title dot ${
                expansion.includes(`${year}m${+item.osaWeekPlanMonth}`)
                  ? 'select'
                  : ''
              }`}
              onClick={(e) =>
                switchExpansion(e, `${year}m${+item.osaWeekPlanMonth}`)
              }
            >
              <span className="text">
                {changeNumToHan(+item.osaWeekPlanMonth)}月
              </span>
            </div>
            <div className="child">{getWeek(item.osaWeekDateList)}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="quarter-box">
      <Typography.Title level={4}>
        <span className="click-icon" onClick={() => changeYear(-1)}>
          <LeftOutlined />
        </span>
        <span style={{ margin: '0 4px' }}>{year}</span>
        <span className="click-icon" onClick={() => changeYear(1)}>
          <RightOutlined />
        </span>
      </Typography.Title>
      {data.map((item, index) => (
        <div
          key={index}
          className={['quarter', disabled ? 'disabled' : ''].join(' ')}
        >
          <div
            className={`quarter-title dot ${
              expansion.includes(`${year}q${index + 1}`) ? 'select' : ''
            }`}
            onClick={(e) => switchExpansion(e, `${year}q${index + 1}`)}
          >
            第{changeNumToHan(item.osaWeekPlanQuarter)}个季度
          </div>
          <div className="child">{getMontn(item.osaWeekMonthList)}</div>
        </div>
      ))}
    </div>
  );
};
