import { memo, useEffect, useState } from 'react';
import HomeCard from './HomeCard';
import WorkCalendar from './WorkCalendar';
import { connect } from 'umi';
import './index.less';
import { holiday } from '@/_assets/json/holiday';
import { Button, Radio } from 'kenshin';
import styles from './styles.less';
import {
  largerCardList,
  getInfoList,
  getUsercenterList,
  getPersonnelList,
  getmounthString,
  getWeekDayString,
  getToDayString,
  getLastDayString,
} from './util/data';
import StatisticsCard from './StatisticsCard';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import {
  getempindexattendancestatdata,
  getindexattendancetotalstatdata,
} from '../../_serveice/attendance';
import { useReactive } from 'ahooks';

const defaultCardList = () => [
  {
    title: '本月考勤统计',
    subTitle: '个人',
    list: getInfoList,
    props: 'data',
  },
  {
    title: '个人工作台',
    subTitle: '个人',
    list: getUsercenterList,
    props: 'work',
  },
];

const handleHomeWork = (data) => {
  let result = {};
  result.partinProjectNum = data.partinProject?.value;
  result.partinWorkPackageNum = data.partinWorkPackage?.value;
  result.weekWorkHourNum = data.weekWorkHour?.value;
  // result.partinProjectDesc = data.partinProject?.percentChange
  // result.partinWorkPackageDesc = data.partinWorkPackage?.percentChange
  // result.weekWorkHourDesc = data.weekWorkHour?.percentChange
  return result;
};

const Employee = ({ dispatch, usercenter, loadingEf }) => {
  let roleList =
    JSON.parse(localStorage.getItem('employeeDTO'))?.roleTypeList || [];
  const [expansion, setExpansion] = useState(false);
  const state = useReactive({
    time: '0.month:0.month',
    data: {},
    personnel: {},
    cardList: defaultCardList(),
    radioDisabled: false,
    showTime: getmounthString(),
    holidayData: holiday,
  });
  useEffect(() => {
    let empId = localStorage.getItem('muId');
    dispatch({
      type: 'usercenter/getEmpHomeWork',
      payload: {
        empId,
      },
    });
    dispatch({
      type: 'usercenter/getEmpWorkRptList',
      payload: {
        empIdString: localStorage.getItem('muId'),
        rptBeginTime: moment()
          .subtract(1, 'month')
          .startOf('M')
          .format('YYYY-MM-DD'),
        rptEndTime: moment().format('YYYY-MM-DD'),
      },
    });
    handleTime(state.time);

    const currentYear = new Date().getFullYear();
    if (currentYear) {
      dispatch({
        type: 'usercenter/getholidaybyyear',
        payload: {
          year: currentYear,
        },
      }).then((res) => {
        const updatedHolidayData = {};
        Object.keys(res).forEach((key) => {
          const newKey = `${currentYear}-${key}`;
          updatedHolidayData[newKey] = res[key];
        });
        console.log(
          'updatedHolidayData...',
          updatedHolidayData,
          state.holidayData,
        );
        if (res) {
          state.holidayData = { ...state.holidayData, ...updatedHolidayData };
        }
      });
    }
  }, []);

  const options = [
    { label: '昨日', value: '-1.d:-1.d' },
    { label: '今日', value: '0.d:0.d' },
    { label: '本周', value: '0.w:0.w' },
    { label: '本月', value: '0.month:0.month' },
  ];
  const getListData = (payload) => {
    getempindexattendancestatdata(payload).then((res) => {
      if (res) {
        var data = res.data;

        if (data) {
          data.holidayEnableDayThisYear += data?.holidayEnableDayLastYear;

          data.annualEnableDayThisYear += data?.annualEnableDayLastYear;

          state.data = data;
        }
      }
      // console.log('11111',data)
    });
    // 人事
    if (roleList.includes('PERSONNEL')) {
      state.radioDisabled = true;
      getindexattendancetotalstatdata(payload).then((res) => {
        state.radioDisabled = false;
        state.personnel = res.data;
        // console.log(222222,res.data)
      });
      state.cardList = [
        {
          title: '考勤情况',
          subTitle: '全员',
          time: '',
          list: getPersonnelList,
          props: 'personnel',
        },
        ...defaultCardList(),
      ];
    }
  };

  const expansionChildren = () => {
    if (expansion)
      return (
        <div className={styles.expansionBtn}>
          收起
          <CaretUpOutlined />
        </div>
      );
    return (
      <div className={styles.expansionBtn}>
        展开
        <CaretDownOutlined />
      </div>
    );
  };

  // 切换时间
  const handleTime = (timeStr) => {
    state.time = timeStr;
    let timeArr = timeStr.split(':').map((item, i) => {
      let [diff, unit] = item.split('.');
      let times = moment().add(diff, unit).startOf(unit);
      if (i == 1) times = times.endOf(unit);
      if (unit == 'w') times = times.add(1, 'd'); // 如果是本周，则加一（国际时间本周开始是周日开始）
      return times.format('YYYY-MM-DD HH:mm:ss');
    });
    const [queryBeginTime, queryEndTime] = timeArr;
    // console.log(queryBeginTime,'------',queryEndTime,'------',timeStr)

    if (timeStr.includes('-1.d')) {
      state.showTime = getLastDayString();
    } else if (timeStr.includes('0.d')) {
      state.showTime = getToDayString();
    } else if (timeStr.includes('0.w')) {
      state.showTime = getWeekDayString();
    } else if (timeStr.includes('0.m')) {
      state.showTime = getmounthString();
    }

    // console.log(state.showTime)

    let payload = { queryBeginTime, queryEndTime };
    getListData(payload);
  };
  const cardProps = {
    data: state.data,
    personnel: state.personnel,
    work: { ...state.data, ...handleHomeWork(usercenter.homeWork) },
  };
  return (
    <>
      {/* <div style={{ borderRadius: 8, marginBottom: 20 }}>
        <div className="home-card-title">个人工作台</div>
        <HomeCard data={usercenter.homeWork} />
      </div> */}
      <div style={{ marginBottom: 20, position: 'relative' }}>
        <div
          style={{
            lineHeight: '65px',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            position: 'absolute',
            right: 20,
            top: 20,
            height: 40,
          }}
        >
          {roleList.includes('PERSONNEL') && (
            <Radio.Group
              options={options}
              disabled={state.radioDisabled}
              onChange={(e) => {
                console.log('change');
                handleTime(e.target.value);
              }}
              value={state.time}
              optionType="button"
            />
          )}
          <Button type="text" onClick={() => setExpansion(!expansion)}>
            {expansionChildren()}
          </Button>
        </div>
        {/* <h3 className={styles.title}>考勤情况</h3>
        <div className={styles.cardFlex}>
          {getPersonnelList(state.personnel).map((props, key) => (
            <StatisticsCard key={key} {...props} />
          ))}
        </div> */}
        <div className={styles.titleFlex}>
          <h3 className={styles.title}>{state.cardList[0].title}</h3>
          {roleList.includes('PERSONNEL') && (
            <div className={styles.subtitle}>{state.cardList[0].subTitle}</div>
          )}
          {roleList.includes('PERSONNEL') && (
            <div className={styles.subtitle}>{state.showTime}</div>
          )}
        </div>

        <div className={styles.cardFlex}>
          {state.cardList[0]
            .list?.(cardProps[state.cardList[0].props])
            .map((props, key) => (
              <StatisticsCard key={key} {...props} />
            ))}
        </div>
        <div style={{ display: expansion ? 'block' : 'none' }}>
          {state.cardList.slice(1).map((item, i) => (
            <div key={i}>
              <div className={styles.titleFlex}>
                <h3 className={styles.title} style={{ marginTop: 30 }}>
                  {item.title}
                </h3>
                {roleList.includes('PERSONNEL') && (
                  <div className={styles.subtitle1} style={{ marginTop: 30 }}>
                    {item.subTitle}
                  </div>
                )}
                {!roleList.includes('PERSONNEL') && i == 0 && (
                  <h3 className={styles.title_Second} style={{ marginTop: 30 }}>
                    假期统计
                  </h3>
                )}
                {!roleList.includes('PERSONNEL') && i == 0 && (
                  <h3 className={styles.title_third} style={{ marginTop: 30 }}>
                    加班统计
                  </h3>
                )}
                {roleList.includes('PERSONNEL') && i == 1 && (
                  <h3 className={styles.title_forth} style={{ marginTop: 30 }}>
                    假期统计
                  </h3>
                )}
                {roleList.includes('PERSONNEL') && i == 1 && (
                  <h3 className={styles.title_third} style={{ marginTop: 30 }}>
                    加班统计
                  </h3>
                )}
              </div>
              {/* <h3 className={styles.title} style={{ marginTop: 30 }}>
                {item.title}
              </h3> */}
              <div className={styles.cardFlex}>
                {item.list?.(cardProps[item.props]).map((props, key) => (
                  <StatisticsCard key={key} {...props} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <WorkCalendar
        data={usercenter.homeEmpWorkRptList?.list}
        homeHoliday={state.holidayData}
      />
    </>
  );
};

export default connect(({ usercenter, loading }) => ({
  usercenter,
  loadingEf: loading.effects,
}))(Employee);
